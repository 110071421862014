import React, { useState } from 'react';
import { login02, loginlogo } from '../../imagepath';
import { Link } from 'react-router-dom';
import * as ErrorHandling from "../../../helpers/error-handling";
import ApiService from "../../../helpers/ApiService";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

const PasswordChange = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPasswordHidden, setCurrentPasswordHidden] = useState(true);
  const [newPasswordHidden, setNewPasswordHidden] = useState(true);
  const [confirmNewPasswordHidden, setConfirmNewPasswordHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    const baseError = 'Error cambiando la contraseña.';

    if (newPassword !== confirmNewPassword) {
      ErrorHandling.showErrorToast('Las nuevas contraseñas no coinciden.');
      setLoading(false);
      return;
    }

    try {
      await ApiService.post('users/password-change', {
        email,
        currentPassword,
        newPassword,
        confirmNewPassword
      }, baseError, { headers: { 'Accept-Language': 'es' } });

      
      ErrorHandling.showSuccessToast('Contraseña cambiada exitosamente.');
      setShowConfirmation(true);
      
    } catch (error) {
      // Error handling is managed in ApiService, showing toast messages automatically
    } finally {
      setLoading(false);
    }
  };

  const toggleCurrentPasswordVisibility = () => {
    setCurrentPasswordHidden(!currentPasswordHidden);
  };

  const toggleNewPasswordVisibility = () => {
    setNewPasswordHidden(!newPasswordHidden);
  };

  const toggleConfirmNewPasswordVisibility = () => {
    setConfirmNewPasswordHidden(!confirmNewPasswordHidden);
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Password Change Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="#">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Cambiar Contraseña</h2>
                      {showConfirmation ? (
                        <div>
                          <h2>Contraseña cambiada exitosamente.</h2>
                          <div className="next-sign">
                            <p className="account-subtitle">
                              <Link to="/login">Inicie sesión</Link> con su nueva contraseña aquí.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={handlePasswordChange}>
                          <div className="form-group">
                            <label>
                              Correo Electrónico <span className="login-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              value={email}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div className="form-group">
                            <label>
                              Contraseña Actual <span className="login-danger">*</span>
                            </label>
                            <input
                              type={currentPasswordHidden ? 'password' : 'text'}
                              className="form-control pass-input"
                              value={currentPassword}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setCurrentPassword(e.target.value)}
                            />
                            <span
                              className="toggle-password"
                              onClick={toggleCurrentPasswordVisibility}
                            >
                              {currentPasswordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>
                              Nueva Contraseña <span className="login-danger">*</span>
                            </label>
                            <input
                              type={newPasswordHidden ? 'password' : 'text'}
                              className="form-control pass-input"
                              value={newPassword}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span
                              className="toggle-password"
                              onClick={toggleNewPasswordVisibility}
                            >
                              {newPasswordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>
                              Confirmar Nueva Contraseña <span className="login-danger">*</span>
                            </label>
                            <input
                              type={confirmNewPasswordHidden ? 'password' : 'text'}
                              className="form-control pass-input"
                              value={confirmNewPassword}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                            <span
                              className="toggle-password"
                              onClick={toggleConfirmNewPasswordVisibility}
                            >
                              {confirmNewPasswordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                            </span>
                          </div>
                          <div className="form-group login-btn">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loading}
                            >
                              Cambiar Contraseña
                            </button>
                          </div>
                        </form>
                      )}
                      {!showConfirmation && (
                        <div className="next-sign">
                          <p className="account-subtitle">
                            <Link to="/login">Inicie sesión</Link> con su nueva contraseña.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Password Change Content */}
          </div>
        </div>
        {loading && (
          <div className="loading style-2">
            <div className="loading-wheel"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordChange;
