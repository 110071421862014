import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faBuilding, faCalendarCheck, faUserMd, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ApiService from "../../../helpers/ApiService";
import * as Auth from "../../../helpers/auth-helper";

const Admin_Dashboard = () => {
  const [userName, setUserName] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!Auth.userLoggedIn()) {
      window.location.href = "/login";
    } else {
      setUserName(Auth.getUsername());
      fetchDashboardData();
      fetchTips();
    }
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    const baseError = 'Error fetching dashboard data.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      const response = await ApiService.get(`${officeId}/dashboard/admin`, {}, baseError);
      setDashboardData(response.data);
    } catch (error) {
      // Error handling is already managed in ApiService
    } finally {
      setLoading(false);
    }
  };

  const fetchTips = async () => {
    const baseError = 'Error fetching tips.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      const response = await ApiService.get(`${officeId}/dashboard/admin/tips`, {}, baseError);
      setTips(response.data);
    } catch (error) {
      // Error handling is already managed in ApiService
    }
  };

  if (!dashboardData) return <div></div>;

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-dashboard"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </li>
                  <li className="breadcrumb-item active">Tablero de Administrador</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>
                    Bienvenido, <span>{userName}</span>
                  </h2>
                  <p>Que tengas un excelente día</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Consultorio/Oficina Widget */}
            <div className="col-md-3 col-sm-6 col-lg-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faBuilding} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Consultorio/Oficina</h4>
                  <h2>{dashboardData.office.name}</h2>
                  <p>Tipo de licencia: ({dashboardData.office.licenseType})</p>
                  <p>Limite de doctores: {dashboardData.office.limits.doctorsLimit}</p>
                  <p>Limite de pacientes: {dashboardData.office.limits.patientsLimit}</p>
                </div>
              </div>
            </div>
            {/* Citas Widget */}
            <div className="col-md-3 col-sm-6 col-lg-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Citas Hoy</h4>
                  <h2>
                    <CountUp delay={0.4} end={dashboardData.appointmentsToday} duration={0.6} />
                  </h2>
                  <p>Citas programadas para hoy</p>
                </div>
              </div>
            </div>
            {/* Doctores Widget */}
            <div className="col-md-3 col-sm-6 col-lg-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faUserMd} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Doctores</h4>
                  <h2>
                    <CountUp delay={0.4} end={dashboardData.totalDoctors} duration={0.6} />
                  </h2>
                  <p>Total de doctores registrados</p>
                </div>
              </div>
            </div>
            {/* Pacientes Widget */}
            <div className="col-md-3 col-sm-6 col-lg-3">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faUsers} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Pacientes</h4>
                  <h2>
                    <CountUp delay={0.4} end={dashboardData.totalPatients} duration={0.6} />
                  </h2>
                  <p>Total de pacientes registrados</p>
                </div>
              </div>
            </div>
          </div>
          {/* Tips Section */}
          <div className="tips-section">
            <h3>Consejos para Administradores</h3>
            <ul>
              {tips.map((tip, index) => (
                <li key={index}>
                  <Link to={tip.link}>{tip.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {loading && <div className="loading style-2"><div className="loading-wheel"></div></div>}
      </div>
    </>
  );
};

export default Admin_Dashboard;
