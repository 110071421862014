/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { login02, loginlogo } from '../imagepath';
import { onShowSizeChange, itemRender } from '../Pagination';
import { Link } from 'react-router-dom';
import * as Auth from '../../helpers/auth-helper';

const OfficeList = () => {
  const [officeCleared, setOfficeCleared] = useState(false);
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    if (!Auth.userLoggedIn()) {
      window.location.href = '/login';
    } else {
      Auth.clearCurrentOffice();
      setOffices(Auth.getOfficeRoles);
      setOfficeCleared(true);
    }
  }, []);

  const saveCurrentOffice = (office) => {
    Auth.saveCurrentOffice(office);
  };

  // Helper function to translate roles
  const translateRole = (role) => {
    switch (role) {
      case 'Admin':
        return 'Administrador';
      case 'Doctor':
        return 'Doctor';
      case 'Patient':
        return 'Paciente';
      default:
        return role;
    }
  };

  const columns = [
    {
      width: '10%',
      title: '',
      render: (text, record) => (
        <>
          <div className="text-end">
            <Link
              onClick={() => saveCurrentOffice(record)}
              className="btn btn-primary me-2"
              to={
                record.role === 'Admin'
                  ? '/admin-dashboard'
                  : record.role === 'Doctor'
                  ? '/doctor-dashboard'
                  : '/patient-dashboard'
              }
            >
              Entrar
            </Link>
          </div>
        </>
      ),
    },
    {
      width: '65%',
      title: 'Nombre',
      dataIndex: 'officeName',
    },
    {
      width: '35%',
      title: 'Rol',
      dataIndex: 'role',
      sorter: (a, b) => a.officeName.length - b.officeName.length,
      render: (text) => translateRole(text), // Use translateRole function
    },
  ];

  if (!officeCleared) return <div></div>;

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="#">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>

                      <div className="col-sm-12">
                        <div className="card card-table show-entire">
                          <div className="card-body">
                            {/* Table Header */}
                            <div className="page-table-header mb-2">
                              <div className="row align-items-center">
                                <div className="col">
                                  <div className="doctor-table-blk">
                                    <h3>Consultorios / Clínicas</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* /Table Header */}
                            <div className="table-responsive doctor-list">
                              <Table
                                pagination={{
                                  total: offices.length,
                                  showTotal: (total, range) =>
                                    `Mostrando ${range[0]} a ${range[1]} del total de ${total}`,
                                  onShowSizeChange: onShowSizeChange,
                                  itemRender: itemRender,
                                }}
                                columns={columns}
                                dataSource={offices}
                                rowKey={(record) => record.officeId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeList;
