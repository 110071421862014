import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Auth from '../helpers/auth-helper';

const withAuthCheck = (WrappedComponent) => {
  const ComponentWithAuthCheck = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Check if the user is logged in
      if (!Auth.userLoggedIn()) {
        navigate('/login');
        return;
      }

    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuthCheck;
};

export default withAuthCheck;
