import { toast } from 'react-toastify';

export function obtainErrorMessage(error) {    
    var errorToReturn = '';    
    if(error?.response) {
        if(error.response.status === 400) {
            errorToReturn = 'Error en la solicitud. Revise los datos ingresados.';
        }       
        if(error.response.status === 401) {
            errorToReturn = 'Error de autenticación. Revise usuario y contraseña.';
        }        
        if(error?.response?.data){        
            if(error?.response?.data?.errors){            
                var errors = [];
                for (const obj in error.response.data.errors) {
                    console.log(obj);            
                    errors.push(error.response.data.errors[obj]);
                }
                errorToReturn += "." + errors.join(", ");
            }
            else {
                if(error?.response?.data?.message) {                
                    errorToReturn += error.response.data.message;
                }
                else {    
                    if(error?.response?.data?.name){            
                        errorToReturn += error.response.data.name;
                    }
                    else{
                        errorToReturn += printObject(error.response.data);
                    }
                }
            }    
        }    
    }   
    else {
        errorToReturn = "Hubo un error inesperado. Intente nuevamente.";     
    }
    return errorToReturn;
}

export function requiredField(e) {
    e.target.setCustomValidity('');  
    if (!e.target.validity.valid) {
        if(e.target.validity.valueMissing) {
            e.target.setCustomValidity('Campo requerido');  
        } else {
            if (e.target.validity.typeMismatch && e.target.type === "email") {            
                e.target.setCustomValidity('Correo inválido');
            }        
        }
    }
}

export function printObject(o) {
    return JSON.stringify(o, null, 4);
}

export function showErrorToast(message) {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

export function showSuccessToast(message) {
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
