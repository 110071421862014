import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import ApiService from "../../../helpers/ApiService";
import * as Auth from "../../../helpers/auth-helper";

const PatientDashboard = () => {
  const [userName, setUserName] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!Auth.userLoggedIn()) {
      window.location.href = "/login";
    } else {
      setUserName(Auth.getUsername());
      fetchDashboardData();
      fetchTips();
    }
  }, []);

  const fetchDashboardData = async () => {
    setLoading(true);
    const baseError = 'Error al obtener los datos del tablero.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      const patientId = Auth.getUserId();
      const response = await ApiService.get(`${officeId}/dashboard/patient/${patientId}`, {}, baseError);
      setDashboardData(response.data);
    } catch (error) {
      // Error handling is already managed in ApiService
    } finally {
      setLoading(false);
    }
  };

  const fetchTips = async () => {
    const baseError = 'Error al obtener los consejos.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      const patientId = Auth.getUserId();
      const response = await ApiService.get(`${officeId}/dashboard/patient/${patientId}/tips`, {}, baseError);
      setTips(response.data);
    } catch (error) {
      // Error handling is already managed in ApiService
    }
  };

  if (!dashboardData) return <div></div>;

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="patient-dashboard"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </li>
                  <li className="breadcrumb-item active">Tablero de Paciente</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>
                    Bienvenido, <span>{userName}</span>
                  </h2>
                  <p>Que tengas un excelente día</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Última Cita Widget */}
            <div className="col-md-6 col-sm-6 col-lg-6">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Última cita</h4>
                  {dashboardData.lastAppointment ? (
                    <>
                      <p><strong>Fecha:</strong> {new Date(dashboardData.lastAppointment.start).toLocaleString()}</p>
                      <p><strong>Doctor:</strong> {dashboardData.lastAppointment.doctorName}</p>
                    </>
                  ) : (
                    <p>No hay citas anteriores</p>
                  )}
                </div>
              </div>
            </div>
            {/* Próxima Cita Widget */}
            <div className="col-md-6 col-sm-6 col-lg-6">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faCalendarCheck} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Próxima cita</h4>
                  {dashboardData.nextAppointment ? (
                    <>
                      <p><strong>Fecha:</strong> {new Date(dashboardData.nextAppointment.start).toLocaleString()}</p>
                      <p><strong>Doctor:</strong> {dashboardData.nextAppointment.doctorName}</p>
                    </>
                  ) : (
                    <p>No hay próximas citas</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Tips Section */}
          <div className="tips-section">
            <h3>Consejos para Pacientes</h3>
            <ul>
              {tips.map((tip, index) => (
                <li key={index}>
                  <Link to={tip.link}>{tip.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {loading && <div className="loading style-2"><div className="loading-wheel"></div></div>}
      </div>
    </>
  );
};

export default PatientDashboard;
