import axios from 'axios';
import * as Constants from './constants';
import * as Auth from './auth-helper';
import * as ErrorHandling from './error-handling';


class ApiService {
  
  constructor() {
    this.apiClient = axios.create({
      baseURL: Constants.BASEURL,
      headers: {
        'Accept-Language': 'es',
      }
    });

    // Request interceptor to add token to headers
    this.apiClient.interceptors.request.use(config => {
      const token = Auth.getAuthToken();
      if (token) {
        config.headers['token'] = token;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });

    // Response interceptor to handle errors globally
    this.apiClient.interceptors.response.use(response => {
      return response;
    }, error => {


      if (error.response && error.response.status === 401) {
        const currentPath = window.location.pathname;
        const excludeRedirect = currentPath === '/login' || currentPath === '/passwordchange'; // Adjust this to your login route        
        Auth.logout(); // Clear any stored authentication tokens
        if (!excludeRedirect) {
          window.location.href = '/login';  // Redirect to the login page
        }
      }  
      return Promise.reject(error);
    });
  }

  async get(url, params = {}, baseError = '') {    
    try {     
      const response = await this.apiClient.get(url, { params });      
      return response;
    } catch (error) {
      this.handleApiError(error, baseError);
      throw error;
    }
  }
  async getBlob(url, params = {}, baseError = '') {    
    try {     
      const response = await this.apiClient.get(url, { params, responseType: "blob", });      
      return response;
    } catch (error) {
      this.handleApiError(error, baseError);
      throw error;
    }
  }
  async post(url, data = {}, baseError = '') {
    try {
      const response = await this.apiClient.post(url, data);
      return response;
    } catch (error) {
      this.handleApiError(error, baseError);
      throw error;
    }
  }

  async put(url, data = {}, baseError = '') {
    try {
      const response = await this.apiClient.put(url, data);
      return response;
    } catch (error) {
      this.handleApiError(error, baseError);
      throw error;
    }
  }

  async delete(url, baseError = '') {
    try {
      const response = await this.apiClient.delete(url);
      return response;
    } catch (error) {
      this.handleApiError(error, baseError);
      throw error;
    }
  }

  handleApiError(error, baseError) {
    const customError = ErrorHandling.obtainErrorMessage(error);
    const errorMessage = baseError ? `${baseError}: ${customError}` : customError;
    ErrorHandling.showErrorToast(errorMessage ? `${errorMessage}.` : 'An error occurred.');
  }
}

export default new ApiService();
