/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import React, { useState, useEffect } from 'react';
import { /*Timeline,*/ Table, Button, Dropdown, Menu } from 'antd';
import { onShowSizeChange, itemRender } from '../Pagination';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Select from 'react-select';
import ApiService from "../../helpers/ApiService";
import * as ErrorHandling from "../../helpers/error-handling";
import * as Auth from "../../helpers/auth-helper";
import Header from '../Header';
import Sidebar from '../Sidebar';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

// Extend Day.js with the quarterOfYear plugin
dayjs.extend(quarterOfYear);

const ClinicalHistory = ({ patientId: initialPatientId }) => {
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [selectedRange, setSelectedRange] = useState('1m'); // Default filter is last 1 month
  const role = Auth.getCurrentOffice().role;

  useEffect(() => {
    if (role !== "Patient") {
      loadPatients();
    }
  }, []);

  useEffect(() => {
    if (role === "Patient") {
      const patientId = Auth.getUserId();
      loadMedicalHistory(patientId);
    } else if (selectedPatient || initialPatientId) {
      const patientId = selectedPatient ? selectedPatient.value : initialPatientId;
      loadMedicalHistory(patientId);
    }
  }, [selectedPatient, selectedRange]);

  const loadPatients = async () => {
    try {
      const baseError = 'Error obteniendo la lista de pacientes.';
      const officeId = Auth.getCurrentOffice().officeId;
      const response = await ApiService.get(`${officeId}/patients`, {}, baseError);
      const patientOptions = response.data.map((patient) => ({
        value: patient.patientId,
        label: patient.name.toUpperCase(),
      }));
      setPatients(patientOptions);

      if (initialPatientId) {
        const preselectedPatient = patientOptions.find(patient => patient.value === initialPatientId);
        setSelectedPatient(preselectedPatient);
      }
    } catch (error) {
      ErrorHandling.showErrorToast('Error al cargar la lista de pacientes.');
    }
  };

  const calculateDateRange = () => {
    let startDate, endDate;

    switch (selectedRange) {
      case '1w':
        startDate = dayjs().subtract(1, 'week').format('YYYY-MM-DD');
        endDate = dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case '1m':
        startDate = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
        endDate = dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'q1':
        startDate = dayjs().startOf('year').format('YYYY-MM-DD');
        endDate = dayjs().startOf('year').add(2, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'q2':
        startDate = dayjs().startOf('year').add(3, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = dayjs().startOf('year').add(5, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'q3':
        startDate = dayjs().startOf('year').add(6, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = dayjs().startOf('year').add(8, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'q4':
        startDate = dayjs().startOf('year').add(9, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = dayjs().startOf('year').add(11, 'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        break;
      default: {
        const [year, quarter] = selectedRange.split('-');
        if (year && quarter) {
          startDate = dayjs()
            .year(parseInt(year))
            .quarter(parseInt(quarter))
            .startOf('quarter')
            .format('YYYY-MM-DD');
          endDate = dayjs()
            .year(parseInt(year))
            .quarter(parseInt(quarter))
            .endOf('quarter')
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss');
        } else {
          startDate = dayjs().startOf('year').format('YYYY-MM-DD');
          endDate = dayjs().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }
        break;
      }
    }

    return { startDate, endDate };
  };

  const loadMedicalHistory = async (patientId) => {
    try {
      setLoading(true);
      const { startDate, endDate } = calculateDateRange();
      const baseError = 'Error obteniendo el historial clínico del paciente';
      const officeId = Auth.getCurrentOffice().officeId;
      const response = await ApiService.get(`${officeId}/patients/${patientId}/history?startDate=${startDate}&endDate=${endDate}`, {}, baseError);
      setMedicalHistory(response.data);
    } catch {
      // Already managed
    } finally {
      setLoading(false);
    }
  };

  const handleRangeChange = ({ key }) => {
    setSelectedRange(key);
  };

  const statusToSpanish = (status) => {
    const statusMap = {
      'Completed': 'Completada',
      'Pending': 'Pendiente',
      'Canceled': 'Cancelada',
    };
    return statusMap[status] || status;
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'start',
      key: 'start',
      render: (text) => dayjs(text).format('DD-MM-YYYY HH:mm'),
    },
    {
      title: 'Motivo',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Doctor',
      dataIndex: 'doctorName',
      key: 'doctorName',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Notas',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Diagnóstico',
      dataIndex: 'diagnosis',
      key: 'diagnosis',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) => statusToSpanish(status),
    },
    {
      title: 'Prescripciones',
      dataIndex: 'prescriptions',
      key: 'prescriptions',
      render: (prescriptions) => (
        <ul>
          {prescriptions.map((prescription, index) => (
            <li key={index}>
              {prescription.medicationName}: {prescription.dosageRegimen}
            </li>
          ))}
        </ul>
      ),
    }
  ];

  const currentYear = dayjs().year();

  const rangeOptions = [
    { key: '1w', label: 'Última semana' },
    { key: '1m', label: 'Último mes' },
    { key: 'q1', label: `${currentYear} - Q1 (Ene-Mar)` },
    { key: 'q2', label: `${currentYear} - Q2 (Abr-Jun)` },
    { key: 'q3', label: `${currentYear} - Q3 (Jul-Sep)` },
    { key: 'q4', label: `${currentYear} - Q4 (Oct-Dic)` },
    ...[2023, 2022].flatMap(year => [
      { key: `${year}-1`, label: `(${year}) Q1` },
      { key: `${year}-2`, label: `(${year}) Q2` },
      { key: `${year}-3`, label: `(${year}) Q3` },
      { key: `${year}-4`, label: `(${year}) Q4` }
    ])
  ];

  const rangeMenu = (
    <Menu onClick={handleRangeChange}>
      {rangeOptions.map(option => (
        <Menu.Item key={option.key}>
          {option.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='clinical-history' />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="patient-list">Pacientes</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Historial clínico</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Historial Clínico</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <div className="form-group">
                            <label>Rango de fechas:</label>
                            <Dropdown overlay={rangeMenu}>
                              <Button>
                                {rangeOptions.find(option => option.key === selectedRange)?.label || 'Seleccionar rango'}
                                <FeatherIcon icon="chevron-down" />
                              </Button>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    {role !== "Patient" && (
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <div className="form-group">
                              <label>Seleccione un paciente:</label>
                              <Select
                                options={patients}
                                value={selectedPatient}
                                onChange={setSelectedPatient}
                                placeholder="Seleccionar paciente"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {(selectedPatient || role === "Patient") && (
                      <>
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="doctor-table-blk">
                              <div className="form-group">
                                <div className="patient-info">
                                  <h2>Historial Clínico de {selectedPatient ? selectedPatient.label : 'Paciente Actual'}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: medicalHistory.length,
                        showTotal: (total, range) =>
                          `Mostrando ${range[0]} a ${range[1]} del total de ${total}`,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={medicalHistory}
                      rowKey={(record) => record.appointmentId}
                      loading={loading}
                    />
                  </div>
               {/*    <div>
                    <Timeline mode="top">
                      {medicalHistory.map((item) => (
                        <Timeline.Item key={item.appointmentId || item.date}>
                          <span>{dayjs(item.date).format('DD-MM-YYYY')}</span> - {item.title}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {loading && <div className="loading style-2"><div className="loading-wheel"></div></div>}
        </div>
      </div>
    </div>
  );
};

export default ClinicalHistory;
