import React, { useState, useEffect } from 'react';
import { Table, Modal } from "antd";
import { onShowSizeChange, itemRender } from '../Pagination';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import ApiService from "../../helpers/ApiService";
import * as Auth from "../../helpers/auth-helper";
import * as ErrorHandling from "../../helpers/error-handling";
import moment from "moment";

const DoctorList = () => {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [doctorToDelete, setDoctorToDelete] = useState(null);

  useEffect(() => {
    if (!Auth.userLoggedIn()) {
      window.location.href = "/login";
    } else {
      getDoctors();
    }
  }, []);

  const getDoctors = async () => {
    setLoading(true);
    const baseError = 'Error obteniendo la lista de doctores.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      const response = await ApiService.get(`${officeId}/doctors`, {}, baseError);
      setDoctors(response.data);
    } catch (error) {
      // Error handling is already managed in ApiService
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    const baseError = 'Error eliminando el doctor.';
    try {
      const officeId = Auth.getCurrentOffice().officeId;
      await ApiService.delete(`${officeId}/doctors/${doctorToDelete.doctorId}`, baseError);
      ErrorHandling.showSuccessToast('Doctor eliminado exitosamente.');
      getDoctors(); // Refresh the doctor list
      setDeleteModalVisible(false);
    } catch (error) {
      // Error handling is already managed in ApiService
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Celular",
      dataIndex: "mobilePhoneNumber",
      sorter: (a, b) => a.mobilePhoneNumber.localeCompare(b.mobilePhoneNumber),
    },
    {
      title: "Fecha de nac.",
      dataIndex: "dateOfBirth",
      sorter: (a, b) => a.dateOfBirth.localeCompare(b.dateOfBirth),
      render: (dateOfBirth) => moment(dateOfBirth).format("YYYY/MM/DD"), // Format date without time
    },
    {
      title: "Licencia Profesional",
      dataIndex: "professionalLicense",
      sorter: (a, b) => a.professionalLicense.localeCompare(b.professionalLicense),
    },
    {
      title: "Universidad",
      dataIndex: "university",
      sorter: (a, b) => a.university.localeCompare(b.university),
    },
    {
      title: "Dirección Completa",
      dataIndex: "fullAddress",
      sorter: (a, b) => a.fullAddress.localeCompare(b.fullAddress),
    },
    {
      title: "Especialidad",
      dataIndex: "specialization",
      sorter: (a, b) => a.specialization.localeCompare(b.specialization),
    },
    {
      title: "",
      dataIndex: "doctorId",
      render: (text, record) => (
        <>
          <div>
            <Link to={`/doctorform`} state={{ doctor: record }}>
              <FeatherIcon icon="edit" />
              Editar
            </Link>
          </div>
          <div>
            <Link
              onClick={() => {
                setDoctorToDelete(record);
                setDeleteModalVisible(true);
              }}
            >
              <FeatherIcon icon="trash" />
              Eliminar
            </Link>
          </div>
        </>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <Sidebar id='menu-item1' id1='menu-items1' activeClassName='doctor-list' />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Doctores</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Lista de doctores</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Lista de doctores</h3>
                          <div className="doctor-search-blk">
                            <div className="add-group">
                              <Link to="/DoctorForm" className="btn btn-primary add-pluss ms-2">
                                <FeatherIcon icon="plus" />
                              </Link>
                              <Link onClick={getDoctors} className="btn btn-primary doctor-refresh ms-2">
                                <FeatherIcon icon="refresh-cw" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: doctors.length,
                        showTotal: (total, range) =>
                          `Mostrando ${range[0]} a ${range[1]} del total de ${total}`,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      columns={columns}
                      dataSource={doctors}
                      rowKey={(record) => record.doctorId}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && <div className="loading style-2"><div className="loading-wheel"></div></div>}
        {/* Delete Confirmation Modal */}
        <Modal
          title="Confirmar eliminación"
          visible={deleteModalVisible}
          onOk={handleDelete}
          onCancel={() => setDeleteModalVisible(false)}
          okText="Eliminar"
          cancelText="Cancelar"
        >
          <p>¿Está seguro de que desea eliminar este doctor?</p>
        </Modal>
      </div>
    </div>
  );
};

export default DoctorList;
