export function saveToken(token){    
    
    var jsonPayload = parseJwt(token);
    localStorage.setItem('authToken', token);
    localStorage.setItem('officeRoles',jsonPayload.roles);
    localStorage.setItem('authTokenParsed',JSON.stringify(jsonPayload));
    localStorage.setItem('userId',removeDoubleQuotes(jsonPayload.userId));
    //localStorage.setItem('userName',removeDoubleQuotes(jsonPayload.userName));
    localStorage.setItem('userEmail',removeDoubleQuotes(jsonPayload.userEmail));
  
}    
function removeDoubleQuotes(str) {
    return str.replace(/^"(.*)"$/, '$1')
}
export function getOfficeRoles(){          
    var offices = localStorage.getItem('officeRoles');
    return JSON.parse(offices);  
}    
export function getAuthToken(){          
    return localStorage.getItem('authToken');    
}   
export function logout(){          
    localStorage.clear();    
}   
export function getUsername(){          
    return getCurrentOffice().name;
}  
export function getUserId(){          
    return localStorage.getItem('userId');
}  
export function getUserEmail(){          
    return localStorage.getItem('userEmail');
}  
export function getCurrentOffice(){          
    var offices = localStorage.getItem('office'); 
    if(!offices){
        return undefined;
    }   

    return JSON.parse(offices);  
}  
export function saveCurrentOffice(office){        
    localStorage.setItem('office', JSON.stringify(office));
}
export function userLoggedIn(){        
    return localStorage.getItem('authToken') !== null;
}
export function clearCurrentOffice(){        
    localStorage.removeItem('office');
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}