import React, { useState, useEffect } from 'react';
import { login02, loginlogo } from '../../imagepath';
import { Link } from 'react-router-dom';
import * as ErrorHandling from "../../../helpers/error-handling";
import ApiService from "../../../helpers/ApiService";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [passwordResetToken, setPasswordResetToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const emailFromUrl = params.get('email');
    const tokenFromUrl = params.get('paswordResetToken');

    if (!emailFromUrl || !tokenFromUrl) {
      setError('Error en el restablecimiento de contraseña. Confirme su enlace de restablecimiento.');
      ErrorHandling.showErrorToast('Error en el restablecimiento de contraseña. Confirme su enlace de restablecimiento.');
    } else {
      setEmail(emailFromUrl);
      setPasswordResetToken(tokenFromUrl);
    }
  }, []);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    const baseError = 'Error restableciendo la contraseña.';

    if (password !== confirmPassword) {
      ErrorHandling.showErrorToast('Las contraseñas no coinciden.');
      setLoading(false);
      return;
    }

    try {
      await ApiService.post('users/password-reset', {
        email,
        passwordResetToken,
        password,
        confirmPassword
      }, baseError, { headers: { 'Accept-Language': 'es' } });

      ErrorHandling.showSuccessToast('Contraseña restablecida exitosamente.');
      setShowConfirmation(true);
      
    } catch (error) {
      // Error handling is managed in ApiService, showing toast messages automatically
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordHidden(!confirmPasswordHidden);
  };

  if (!email || !passwordResetToken) return <div></div>;

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Password Reset Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="#">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Restablecer Contraseña</h2>
                      {error ? (
                        <div className="error-box">
                          <p>{error}</p>
                        </div>
                      ) : showConfirmation ? (
                        <div>
                          <h2>Contraseña restablecida exitosamente.</h2>
                          <div className="next-sign">
                            <p className="account-subtitle">
                              <Link to="/login">Inicie sesión</Link> con su nueva contraseña aquí.
                            </p>
                          </div>
                        </div>
                      ) : (
                        <form onSubmit={handlePasswordReset}>
                          <div className="form-group">
                            <label>
                              Nueva Contraseña <span className="login-danger">*</span>
                            </label>
                            <input
                              type={passwordHidden ? 'password' : 'text'}
                              className="form-control pass-input"
                              value={password}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>
                              Confirmar Contraseña <span className="login-danger">*</span>
                            </label>
                            <input
                              type={confirmPasswordHidden ? 'password' : 'text'}
                              className="form-control pass-input"
                              value={confirmPassword}
                              required
                              onInvalid={(e) => ErrorHandling.requiredField(e)}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <span
                              className="toggle-password"
                              onClick={toggleConfirmPasswordVisibility}
                            >
                              {confirmPasswordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                            </span>
                          </div>
                          <div className="form-group login-btn">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={loading}
                            >
                              Restablecer Contraseña
                            </button>
                          </div>
                        </form>
                      )}
                      {!showConfirmation && (
                        <div className="next-sign">
                          <p className="account-subtitle">
                            <Link to="/login">Inicie sesión</Link> con su nueva contraseña.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Password Reset Content */}
          </div>
        </div>
        {loading && (
          <div className="loading style-2">
            <div className="loading-wheel"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordReset;
