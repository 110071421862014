import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';

const Upgrade = () => {
  // Inline style for the "Próximamente" badge
  const badgeStyle = {
    display: 'inline-block',
    marginTop: '10px',
    padding: '5px 10px',
    backgroundColor: 'darkgreen',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '5px',
    fontSize: '0.9em',
  };

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="upgrade"
      />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#">Licencias</Link>
                  </li>
                  <li className="breadcrumb-item active">Actualizar Licencia</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="good-morning-blk">
            <div className="row">
              <div className="col-md-6">
                <div className="morning-user">
                  <h2>Actualiza tu Licencia</h2>
                  <p>Explora nuestras opciones para obtener más beneficios.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Pro License */}
            <div className="col-md-6 col-lg-6">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faStar} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Pro</h4>
                  <h2>Licencia Pro</h2>
                  <p>Hasta 10 doctores</p>
                  <p>Hasta 100 pacientes</p>
                  <span style={badgeStyle}>Próximamente</span>
                </div>
              </div>
            </div>
            {/* Unlimited License */}
            <div className="col-md-6 col-lg-6">
              <div className="dash-widget">
                <div className="dash-boxs comman-flex-center">
                  <FontAwesomeIcon icon={faInfinity} size="2x" />
                </div>
                <div className="dash-content dash-count">
                  <h4>Unlimited</h4>
                  <h2>Licencia Unlimited</h2>
                  <p>Doctores ilimitados</p>
                  <p>Pacientes ilimitados</p>
                  <span style={badgeStyle}>Próximamente</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upgrade;
