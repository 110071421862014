import React from 'react';
import { login02, loginlogo } from '../../imagepath';
import { Link } from 'react-router-dom';
import * as ErrorHandling from "../../../helpers/error-handling";

const Registered = () => {
  React.useEffect(() => {
    ErrorHandling.showSuccessToast('Cuenta registrada exitosamente. Revise su correo para confirmar su cuenta.');
  }, []);

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="#">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Listo, cuenta registrada</h2>
                      <h2>Revise su correo para confirmar su cuenta</h2>

                      <div className="next-sign">
                        <p className="account-subtitle">
                          <Link to="/login">Inicie sesión</Link> una vez confirmada su cuenta.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Registered;
