import React from "react";
import {
  login02, 
  loginlogo,
} from "../../imagepath";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import * as ErrorHandling from "../../../helpers/error-handling";
import axios from 'axios'; 
import * as Constants from "../../../helpers/constants";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
const Signup = () => {
  const navigate = useNavigate();
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordHidden1, setPassworHidden1] = useState(true);
  const [selectedLicenseType, setSelectedLicenseType] = useState( { value: 1, label: "Limitada/Gratis" });
  const [password1, setPassword1] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [office, setOffice] = useState('');
  const [licenseType, _] = useState([
    { value: 1, label: "Limitada/Gratis" },    
  ]);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  
  
  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };


  const togglePasswordVisibility1 = () => {
    setPassworHidden1(!passwordHidden1);
  };

  
  const handleRegister = async (e) => {
    try {      
      setLoading(true);
      e.preventDefault(); // Prevent the default form submission behavior
      setError('');
      await axios.post(Constants.BASEURL + 'users/register',  {   
        email: email,
        password: password,
        confirmPassword: password1,
        name: name,
        office: office,
        licenseType: selectedLicenseType.value
      }, { headers: {
        'Accept-Language': 'es'        
    }}) .then( response => {
        if(response.status >=200 && response.status < 300)
        {
          navigate("/registered");
        }
        else
        {
          setError('Error en el registro. ' + response);
        }
     })
     .catch(error => {
      const customError = ErrorHandling.obtainErrorMessage(error);
      setError('Error en el registro. ' + customError ? customError + ".": '');   
     })      
      
    } catch (error) {
      const customError = ErrorHandling.obtainErrorMessage(error);
      setError('Error en el registro. ' + customError ? customError + ".": '');    
    }
    finally{  
      setLoading(false);
    }
  };


  return (
    
    <div>
      <div className="main-wrapper login-body">       
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo}alt="#"/>
                        </Link>
                      </div>
                      <h2>Registro de un consultorio o clínica</h2>
                      {/* Form */}
                      <form onSubmit={handleRegister}>
                        <div className="form-group">
                          <label>
                            Nombre completo <span className="login-danger">*</span>
                          </label>
                          <input required onInvalid={(e) => ErrorHandling.requiredField(e)}  id="name" className="form-control" type="text"  value={name}  onChange={(e) => setName(e.target.value)}  />
                        </div>
                        <div className="form-group">
                          <label>
                            Nombre del consultorio o la clínica<span className="login-danger">*</span>
                          </label>                          
                          <input required onInvalid={(e) => ErrorHandling.requiredField(e)}  id="office" className="form-control" type="text"  value={office} onChange={(e) => setOffice(e.target.value)} />
                        </div>

                        <div className="form-group local-forms">
                                <label>Tipo de licencia</label>
                          <div>
                            <Select
                                  defaultValue={selectedLicenseType}
                                  onChange={setSelectedLicenseType}
                                  options={licenseType}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                       boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                      '&:hover': {
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                      },
                                      borderRadius: '10px',
                                      fontSize: "14px",
                                        minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                      transition: '250ms',
                                      width: '35px',
                                      height: '35px',
                                    }),
                                  }}                                  
                              />
                            </div>
                        </div>
                        
                        <div className="form-group">
                          <label>
                            Correo electrónico <span className="login-danger">*</span>
                          </label>
                          <input required onInvalid={(e) => ErrorHandling.requiredField(e)}  id="email" className="form-control" type="text" value={email} onChange={(e) => setEmail(e.target.value)}  />
                        </div>
                        <div className="form-group">
                          <label>
                            Contraseña <span className="login-danger">*</span>
                          </label>
                          <input
                           required onInvalid={(e) => ErrorHandling.requiredField(e)} 
                          type={passwordHidden ? 'password' : ''}
                          className="form-control pass-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                        </div>
                        <div className="form-group">
                          <label>
                            Confirmar contraseña{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                           required onInvalid={(e) => ErrorHandling.requiredField(e)} 
                          type={passwordHidden1 ? 'password' : ''}
                          className="form-control pass-input"
                          value={password1}
                          onChange={(e) => setPassword1(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility1}
                        >
                          {passwordHidden1 ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                          {/* <input
                            className="form-control pass-input-confirm"
                            type="password"
                          /> */}
                          <span className="profile-views feather-eye-off confirm-password" />
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Estoy de acuerdo con los{" "}
                              <Link to="#">&nbsp; términos y condiciones</Link>
                              &nbsp; y{" "}
                              <Link to="#">&nbsp; la política de privacidad </Link>
                              <input type="checkbox" name="radio" onChange={(e) => setTermsAndConditions(e.target.checked)}/>
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="form-group login-btn">
                          <button disabled={!termsAndConditions}
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            Registrar
                          </button>
                        </div>
                      </form>
                      {/* /Form */}

                      {error &&
                        <div className="error-box">
                          <p>{error}</p>                          
                        </div>
                      }
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Ya eres el administrador de un consultorio o clínica? <Link to="/login">Inicia sesión</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>        
      </div>    
    {loading &&
      <div className="loading style-2"><div className="loading-wheel"></div></div>
    }
      
    </div>
  );
};

export default Signup;
