/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { DatePicker, Space, Modal, Button, Tabs, Table, Input, Popover, Checkbox } from 'antd';
import dayjs from 'dayjs';
import ApiService from "../../helpers/ApiService";
import * as ErrorHandling from '../../helpers/error-handling';
import { Printer, Trash } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faHeartbeat, faNotesMedical, faPills } from '@fortawesome/free-solid-svg-icons';

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const statusOptions = [
  { value: 'Pending', label: 'Pendiente' },
  { value: 'Completed', label: 'Completada' },
  { value: 'Canceled', label: 'Cancelada' },
];

const AppointmentForm = ({
  isOpen,
  onRequestClose,
  currentEvent,
  setCurrentEvent,
  viewType,
  doctors,
  patients,
  isEditDelete,
  handleSubmit,
  selectedPatient,
  setSelectedPatient,
  selectedDoctor,
  setSelectedDoctor,
}) => {
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [newPrescription, setNewPrescription] = useState({ medicationName: '', dosageRegimen: '' });
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [visible, setVisible] = useState(false);
  const [selectedReminders, setSelectedReminders] = useState([]);
  const [allergyInput, setAllergyInput] = useState('');

  useEffect(() => {
    if (isOpen) {
      setActiveTabKey('1');
      setAllergyInput('');
      setNewPrescription({ medicationName: '', dosageRegimen: '' });
    }
  }, [isOpen]);

  const handleChangeDates = (dates) => {
    setCurrentEvent({
      ...currentEvent,
      start: dates[0].toISOString(),
      end: dates[1].toISOString(),
      extendedProps: {
        ...currentEvent.extendedProps,
      },
    });
  };

  const showDeleteConfirm = () => {
    setIsDeleteConfirmVisible(true);
  };

  const handleOk = () => {
    const fakeEvent = { nativeEvent: { submitter: { id: 'save' } } };
    handleSubmit(fakeEvent);
  };

  const handleDelete = () => {
    setIsDeleteConfirmVisible(false);
    const fakeEvent = { nativeEvent: { submitter: { id: 'delete' } } };
    handleSubmit(fakeEvent);
  };

  const handleCancelDelete = () => {
    setIsDeleteConfirmVisible(false);
  };

  const handlePrescriptionChange = (index, key, value) => {
    setCurrentEvent((prevEvent) => {
      const updatedPrescriptions = [...(prevEvent.extendedProps.prescriptions || [])];
      updatedPrescriptions[index] = {
        ...updatedPrescriptions[index],
        [key]: value,
      };

      return {
        ...prevEvent,
        start: prevEvent.start,
        end: prevEvent.end,
        title: prevEvent.title,
        extendedProps: {
          ...prevEvent.extendedProps,
          prescriptions: updatedPrescriptions,
        },
      };
    });
  };

  const addPrescription = () => {
    if (newPrescription.medicationName && newPrescription.dosageRegimen) {
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        start: prevEvent.start,
        end: prevEvent.end,
        title: prevEvent.title,
        extendedProps: {
          ...prevEvent.extendedProps,
          prescriptions: [...(prevEvent.extendedProps.prescriptions || []), newPrescription],
        },
      }));
      setNewPrescription({ medicationName: '', dosageRegimen: '' });
    }
  };

  const removePrescription = (index) => {
    setCurrentEvent((prevEvent) => {
      const updatedPrescriptions = [...(prevEvent.extendedProps.prescriptions || [])];
      updatedPrescriptions.splice(index, 1);
      return {
        ...prevEvent,
        start: prevEvent.start,
        end: prevEvent.end,
        title: prevEvent.title,
        extendedProps: {
          ...prevEvent.extendedProps,
          prescriptions: updatedPrescriptions,
        },
      };
    });
  };

  const addAllergy = () => {
    if (allergyInput) {
      setCurrentEvent((prevEvent) => ({
        ...prevEvent,
        start: prevEvent.start,
        end: prevEvent.end,
        title: prevEvent.title,
        extendedProps: {
          ...prevEvent.extendedProps,
          allergies: [...(prevEvent.extendedProps.allergies || []), allergyInput.trim()],
        },
      }));
      setAllergyInput('');
    }
  };

  const removeAllergy = (index) => {
    setCurrentEvent((prevEvent) => {
      const updatedAllergies = [...(prevEvent.extendedProps.allergies || [])];
      updatedAllergies.splice(index, 1);
      return {
        ...prevEvent,
        start: prevEvent.start,
        end: prevEvent.end,
        title: prevEvent.title,
        extendedProps: {
          ...prevEvent.extendedProps,
          allergies: updatedAllergies,
        },
      };
    });
  };

  const printReport = async () => { 
    try {
      const baseError = "Error obteniendo reporte.";
      
      const response = await ApiService.get(
        `${currentEvent.extendedProps.officeId}/patients/${currentEvent.extendedProps.patientId}/appointments/${currentEvent.extendedProps.appointmentId}/summary`, 
        {}, 
        baseError
      );
      
      if (response.data && response.data.base64Content) {
        const pdfData = atob(response.data.base64Content);
        const byteNumbers = new Array(pdfData.length);
  
        for (let i = 0; i < pdfData.length; i++) {
          byteNumbers[i] = pdfData.charCodeAt(i);
        }
  
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
  
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.data.fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
  
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(baseError);
      }
    } catch (error) {     
      const finalError = ErrorHandling.obtainErrorMessage(error);
      ErrorHandling.showErrorToast('Error al imprimir el reporte.', finalError);
    }
  };

  const printPrescription = async () => {
    try {
      const baseError = "Error obteniendo la receta médica.";

      const response = await ApiService.get(
        `${currentEvent.extendedProps.officeId}/patients/${currentEvent.extendedProps.patientId}/appointments/${currentEvent.extendedProps.appointmentId}/prescription`,
        {},
        baseError
      );

      if (response.data && response.data.base64Content) {
        const pdfData = atob(response.data.base64Content);
        const byteNumbers = new Array(pdfData.length);

        for (let i = 0; i < pdfData.length; i++) {
          byteNumbers[i] = pdfData.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${response.data.fileName}`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(baseError);
      }
    } catch (error) {
      const finalError = ErrorHandling.obtainErrorMessage(error);
      ErrorHandling.showErrorToast('Error al imprimir la receta médica.', finalError);
    }
  };

  const sendReminder = async () => {
    try {
      const queryString = selectedReminders.map(rt => `type=${rt}`).join('&');
      await ApiService.post(
        `${currentEvent.extendedProps.officeId}/doctors/${currentEvent.extendedProps.doctorId}/appointments/${currentEvent.extendedProps.appointmentId}/reminder?${queryString}`
      );
      ErrorHandling.showSuccessToast('Recordatorio enviado con éxito');
      setVisible(false);
    } catch (error) {
      ErrorHandling.showErrorToast(ErrorHandling.obtainErrorMessage(error));
    }
  };

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedReminders(checkedValues);
  };

  const reminderContent = (
    <div>
      <Checkbox.Group onChange={handleCheckboxChange} value={selectedReminders}>
        <Checkbox value="email">Por correo</Checkbox>
        <Checkbox disabled value="whatsapp">Por whatsapp</Checkbox>
      </Checkbox.Group>
      <Button
        type="primary"
        onClick={sendReminder}
        disabled={selectedReminders.length === 0}
        style={{ marginTop: '8px' }}
      >
        Enviar Recordatorio
      </Button>
    </div>
  );

  const columns = [
    {
      title: 'Medicamento',
      dataIndex: 'medicationName',
      key: 'medicationName',
      width: '25%', // 20% of the table width
      render: (text, record, index) => (
        <Input
          value={text}
          readOnly={viewType === 'Patient'}
          onChange={(e) => handlePrescriptionChange(index, 'medicationName', e.target.value)}
        />
      ),
    },
    {
      title: 'Régimen de dosis',
      dataIndex: 'dosageRegimen',
      key: 'dosageRegimen',
      width: '65%', // 65% of the table width
      render: (text, record, index) => (
        <Input
          value={text}
          readOnly={viewType === 'Patient'}
          onChange={(e) => handlePrescriptionChange(index, 'dosageRegimen', e.target.value)}
        />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: '10%', // 15% of the table width
      render: (text, record, index) => (
        <Button type="danger" icon={<Trash />} disabled={viewType === 'Patient'} onClick={() => removePrescription(index)}>
          Eliminar
        </Button>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={viewType === 'Patient' ? 'Ver' : isEditDelete ? 'Editar' : 'Agregar cita'}
        visible={isOpen}
        onCancel={onRequestClose}
        onOk={handleOk}
        okText="Guardar"
        cancelText="Cancelar"
        width={800}
        footer={[
          <Button key="cancel" onClick={onRequestClose}>
            Cancelar
          </Button>,
          <Button key="save" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
          isEditDelete && (
            <Button key="delete" type="primary" danger onClick={showDeleteConfirm}>
              Eliminar
            </Button>
          ),
          currentEvent?.extendedProps?.status === 'Completed' && (
            <Button key="print" type="default" icon={<Printer />} onClick={printReport}>
              Imprimir resumen de la cita
            </Button>
          ),
          isEditDelete && (viewType === 'Doctor' || viewType === 'Admin') && (
            <Popover key="popover"
              content={reminderContent}
              title="Selecciona tipos de recordatorio"
              trigger="click"
              visible={visible}
              onVisibleChange={handleVisibleChange}
            >
              <Button key="reminder" type="primary">
                Recordatorio
              </Button>
            </Popover>
          ),
        ]}
      >
        <Tabs activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)}>
          <TabPane tab={<span><FontAwesomeIcon icon={faInfoCircle} /> Información General</span>} key="1">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                {viewType === 'Admin' && !isEditDelete && currentEvent?.selectedDoctorAdminView && (
                  <div className="form-group mb-0">
                    <label className="custom_check w-100">Agregar cita para el doctor: {currentEvent?.selectedDoctorAdminView?.label}</label>
                  </div>
                )}
                {viewType === 'Patient' && (
                  <div>
                    <label>
                      Doctor: <span className="text-danger">*</span>
                    </label>
                    <Select isDisabled={viewType === 'Patient'} options={doctors} value={selectedDoctor} onChange={(e) => setSelectedDoctor(e)} />
                  </div>
                )}
              </div>
              <div className="form-group">
                {(viewType === 'Doctor' || viewType === 'Admin') && (
                  <div>
                    <label>
                      Paciente: <span className="text-danger">*</span>
                    </label>
                    <Select options={patients} value={selectedPatient} onChange={(e) => setSelectedPatient(e)} />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>
                  Motivo: <span className="text-danger">*</span>
                </label>
                <input
                  readOnly={viewType === 'Patient'}
                  className="form-control"
                  type="text"
                  value={currentEvent?.title}
                  required
                  onInvalid={(e) => ErrorHandling.requiredField(e)}
                  onChange={(e) =>
                    setCurrentEvent((prevEvent) => ({
                      ...prevEvent,
                      start: prevEvent.start,
                      end: prevEvent.end,
                      title: e.target.value,
                      extendedProps: {
                        ...prevEvent.extendedProps,
                      },
                    }))
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  Descripción: <span className="text-danger">*</span>
                </label>
                <input
                  readOnly={viewType === 'Patient'}
                  className="form-control"
                  type="text"
                  value={currentEvent?.extendedProps?.description}
                  required
                  onInvalid={(e) => ErrorHandling.requiredField(e)}
                  onChange={(e) =>
                    setCurrentEvent((prevEvent) => ({
                      ...prevEvent,
                      start: prevEvent.start,
                      end: prevEvent.end,
                      title: prevEvent.title,
                      extendedProps: {
                        ...prevEvent.extendedProps,
                        description: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="form-group">
                <label>
                  Status: <span className="text-danger">*</span>
                </label>
                <Select
                  value={statusOptions.find((option) => option.value === currentEvent?.extendedProps?.status)}
                  onChange={(selectedOption) =>
                    setCurrentEvent((prevEvent) => ({
                      ...prevEvent,
                      start: prevEvent.start,
                      end: prevEvent.end,
                      title: prevEvent.title,
                      extendedProps: {
                        ...prevEvent.extendedProps,
                        status: selectedOption.value,
                      },
                    }))
                  }
                  options={statusOptions}
                  isDisabled={viewType === 'Patient'}
                />
              </div>
              <div className="form-group">
                <label>
                  Fecha: <span className="text-danger">*</span>
                </label>
                <Space direction="vertical" size={12}>
                  <RangePicker
                    disabled={[viewType === 'Patient', viewType === 'Patient']}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={[currentEvent?.start ? dayjs(currentEvent.start) : null, currentEvent?.end ? dayjs(currentEvent.end) : null]}
                    onOk={handleChangeDates}
                  />
                </Space>
              </div>
            </form>
          </TabPane>
          <TabPane tab={<span><FontAwesomeIcon icon={faHeartbeat} /> Detalles Médicos</span>} key="2">
            <div className="form-group">
              <label>
                Alergias:
              </label>
              <div style={{ display: 'flex', marginBottom: '8px' }}>
                <Input
                  value={allergyInput}
                  onChange={(e) => setAllergyInput(e.target.value)}
                  placeholder="Agregar alergia"
                />
                <Button onClick={addAllergy} type="primary" style={{ marginLeft: '8px' }}>
                  Agregar   
                </Button>
              </div>
              <ul>
                {(currentEvent.extendedProps.allergies || []).map((allergy, index) => (
                  <li key={index}>
                    {allergy}
                    <Button type="link" onClick={() => removeAllergy(index)} style={{ marginLeft: '8px' }}>
                      Eliminar
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-group">
              <label>
                Peso (kg):
              </label>
              <Input
                type="number"
                value={currentEvent?.extendedProps?.weight || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      weight: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>
                Estatura (cm):
              </label>
              <Input
                type="number"
                value={currentEvent?.extendedProps?.height || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      height: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>
                Presión arterial:
              </label>
              <Input
                placeholder="Ej: 120/80"
                value={currentEvent?.extendedProps?.bloodPressure || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      bloodPressure: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>
                Temperatura °C:
              </label>
              <Input
                type="number"
                value={currentEvent?.extendedProps?.temperature || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      temperature: e.target.value,
                    },
                  }))
                }
              />
            </div>
          </TabPane>
          <TabPane tab={<span><FontAwesomeIcon icon={faNotesMedical} /> Resultados/Notas</span>} key="3">
            <div className="form-group">
              <label>
                Diagnóstico: <span className="text-danger">*</span>
              </label>
              <textarea
                readOnly={viewType === 'Patient'}
                className="form-control"
                value={currentEvent?.extendedProps?.diagnosis || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      diagnosis: e.target.value,
                    },
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>
                Notas: <span className="text-danger">*</span>
              </label>
              <textarea
                readOnly={viewType === 'Patient'}
                className="form-control"
                value={currentEvent?.extendedProps?.notes || ''}
                onChange={(e) =>
                  setCurrentEvent((prevEvent) => ({
                    ...prevEvent,
                    start: prevEvent.start,
                    end: prevEvent.end,
                    title: prevEvent.title,
                    extendedProps: {
                      ...prevEvent.extendedProps,
                      notes: e.target.value,
                    },
                  }))
                }
              />
            </div>
          </TabPane>
          <TabPane tab={<span><FontAwesomeIcon icon={faPills} /> Medicamentos/Prescripciones</span>} key="4">
            <Table
              columns={columns}
              dataSource={currentEvent?.extendedProps?.prescriptions || []}
              rowKey={(record, index) => index}
              pagination={false}
            />
            {viewType !== 'Patient' && (
              <div className="form-group">
                <label>Agregar Nueva Prescripción</label>
                <div className="form-group">
                  <label>Medicamento:</label>
                  <Input
                    value={newPrescription.medicationName}
                    onChange={(e) => setNewPrescription({ ...newPrescription, medicationName: e.target.value })}
                  />
                </div>
                <div className="form-group">
                  <label>Régimen de dosis:</label>
                  <Input.TextArea
                    value={newPrescription.dosageRegimen}
                    onChange={(e) => setNewPrescription({ ...newPrescription, dosageRegimen: e.target.value })}
                  />
                </div>
                <Button type="primary" onClick={addPrescription}>
                  Agregar Prescripción
                </Button>
                <Button
                  type="default"
                  icon={<Printer />}
                  onClick={printPrescription}
                  style={{ marginTop: '10px' }}
                >
                  Imprimir receta médica
                </Button>
              </div>
            )}
          </TabPane>
        </Tabs>
      </Modal>

      <Modal
        title="Confirmar eliminación"
        visible={isDeleteConfirmVisible}
        onOk={handleDelete}
        onCancel={handleCancelDelete}
        okText="Sí, eliminar"
        cancelText="Cancelar"
        okButtonProps={{ danger: true }}
      >
        <p>¿Está seguro que desea eliminar esta cita?</p>
      </Modal>
    </>
  );
};

export default AppointmentForm;
