import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useNavigate, useLocation } from 'react-router-dom';
import { DatePicker } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import ApiService from "../../helpers/ApiService";
import * as Auth from "../../helpers/auth-helper";
import * as ErrorHandling from "../../helpers/error-handling";
import dayjs from 'dayjs';

const DoctorForm = () => {
  const location = useLocation();
  const { doctor = {} } = location.state || {}; // Access doctor data from state
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(doctor.email || '');
  const [name, setName] = useState(doctor.name || '');
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState(doctor.mobilePhoneNumber || '');
  const [dateOfBirth, setDateOfBirth] = useState(doctor.dateOfBirth || dayjs());
  const [professionalLicense, setProfessionalLicense] = useState(doctor.professionalLicense || '');
  const [university, setUniversity] = useState(doctor.university || '');
  const [specialization, setSpecialization] = useState(doctor.specialization || '');
  const [fullAddress, setFullAddress] = useState(doctor.fullAddress || '');
  const [officeId, setOfficeId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const currentOffice = Auth.getCurrentOffice();
    if (currentOffice) {
      setOfficeId(currentOffice.officeId);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for required DatePicker
    if (!dateOfBirth) {
      ErrorHandling.showErrorToast('Fecha de nacimiento es obligatoria.');
      return;
    }

    setLoading(true);

    const baseError = doctor.doctorId ? 'Error actualizando doctor' : 'Error agregando doctor';
    const url = doctor.doctorId ? 
                `${officeId}/doctors/${doctor.doctorId}` : 
                `${officeId}/doctors`;

    const dto = {
      email,
      name,
      mobilePhoneNumber,
      dateOfBirth,
      professionalLicense,
      university,
      specialization,
      fullAddress,
    };

    try {      
      if (doctor.doctorId) {
        await ApiService.put(url, dto, baseError);
      } else {
        await ApiService.post(url, dto, baseError);
      }
      ErrorHandling.showSuccessToast('Doctor guardado exitosamente.');
      navigate("/doctorlist");
    } catch (error) {
      // Errors are already handled in ApiService interceptors
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="doctorform" />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/doctorlist">Doctors</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {doctor.doctorId ? 'Editar Doctor' : 'Agregar Doctor'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-8">
                        <div className="form-group local-forms">
                          <label>Nombre <span className="login-danger">*</span></label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            value={name}
                            onInvalid={(e) => ErrorHandling.requiredField(e)}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms">
                          <label>Email <span className="login-danger">*</span></label>
                          <input
                            className="form-control"
                            type="email"
                            required
                            onInvalid={(e) => ErrorHandling.requiredField(e)}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />                   
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-8">
                        <div className="form-group local-forms">
                          <label>Celular <span className="login-danger">*</span></label>
                          <input
                            className="form-control"
                            type="text"
                            required
                            onInvalid={(e) => ErrorHandling.requiredField(e)}
                            value={mobilePhoneNumber}
                            onChange={(e) => setMobilePhoneNumber(e.target.value)}
                          />
                        </div>
                      </div>                    
                      <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group local-forms calendar-icon">
                          <label>
                            Fecha de nacimiento: <span className="login-danger">*</span>
                          </label>
                          <DatePicker
                            className="form-control datetimepicker"
                            value={dayjs(dateOfBirth)}
                            placeholder="Seleccione fecha"
                            onChange={(date) => setDateOfBirth(date)}
                            suffixIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group local-forms">
                          <label>Cédula Profesional</label>
                          <input
                            className="form-control"
                            type="text"
                            value={professionalLicense}
                            onChange={(e) => setProfessionalLicense(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group local-forms">
                          <label>Universidad</label>
                          <input
                            className="form-control"
                            type="text"
                            value={university}
                            onChange={(e) => setUniversity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group local-forms">
                          <label>Especialidad</label>
                          <input
                            className="form-control"
                            type="text"
                            value={specialization}
                            onChange={(e) => setSpecialization(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group local-forms">
                          <label>Domicilio completo</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            value={fullAddress}
                            onChange={(e) => setFullAddress(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="doctor-submit text-end">
                        <button type="submit" className="btn btn-primary submit-form">Guardar</button>
                        <button type="button" className="btn btn-primary cancel-form" onClick={() => navigate("/doctorlist")}>Cancelar</button>
                      </div>
                    </div>
                  </form>
                  {loading && <div className="loading style-2"><div className="loading-wheel"></div></div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorForm;
