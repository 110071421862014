import React, { useEffect, useState, useRef } from "react";
import esLocale from '@fullcalendar/core/locales/es';
import { Link, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import ApiService from "../../helpers/ApiService";
import * as ErrorHandling from "../../helpers/error-handling";
import * as Auth from "../../helpers/auth-helper";
import dayjs from "dayjs";
import * as DateHelper from '../../helpers/date-helper';
import AppointmentForm from './AppointmentForm';
import Select from 'react-select';

const Calendar = () => {
  const calendarRef = useRef(null);
  const defaultEvent = {
    start: dayjs(),
    end: dayjs(),
    extendedProps: {
      patientName: "",
      patientId: null,
      description: "",
      status: "pending", // default status
      officeId: Auth.getCurrentOffice()?.officeId,
    },
    title: "",
    doctorId: Auth.getUserId(),
  };

  const [selectedPatient, setSelectedPatient] = useState({});
  const [currentCalendarDates, setCurrentCalendarDates] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState({});
  const [doctorsAdminView, setDoctorsAdminView] = useState([]);
  const [selectedDoctorAdminView, setSelectedDoctorAdminView] = useState({});
  const [patients, setPatients] = useState([]);
  const [viewType, setViewType] = useState("Doctor");
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(defaultEvent);
  const [isEditDelete, setIsEditDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAppointmentFormOpen, setIsAppointmentFormOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const initialize = async () => {
     
      if (!Auth.userLoggedIn()) {
        navigate("/login")
      } 
      else{
        if (!Auth.getCurrentOffice()) {
          navigate("/offices")
        } 
        else{
          const calendarApi = calendarRef.current.getApi();
          calendarApi.on('datesSet', dateChangeHandler);    
          const role = Auth.getCurrentOffice().role;
          if (role === "Patient") {
            setViewType("Patient");
            await loadDoctors();
          } else {
            await loadPatients();
            if (role === "Admin") {
              setViewType("Admin");
              await loadDoctors();
            } else {
              setViewType("Doctor");
            }
          }
          setCurrentCalendarDates([
            DateHelper.getIsoDateYYYYMMDD(calendarApi.view.currentStart),
            DateHelper.getIsoDateYYYYMMDD(calendarApi.view.currentEnd),
          ]);
        }      
      }      
    };
    initialize();
  }, []);

  useEffect(() => {
    loadAppointments();
  }, [selectedDoctorAdminView, currentCalendarDates]);

  const loadAppointments = async () => {
    if(!currentCalendarDates || currentCalendarDates.length != 2)
    {
      return;
    }
    const baseError = 'Error obteniendo la lista de citas.';
    try {
      setLoading(true);
      setError('');
      const officeId = Auth.getCurrentOffice().officeId;
      let url = `${officeId}/appointments`;

      if (viewType === "Patient") {
        const patientId = Auth.getUserId();
        url = `${officeId}/patients/${patientId}/appointments?startDate=${currentCalendarDates[0]}&endDate=${currentCalendarDates[1]}`;
      } else {
        const doctorId = selectedDoctorAdminView?.value || Auth.getUserId();
        url = `${officeId}/doctors/${doctorId}/appointments?startDate=${currentCalendarDates[0]}&endDate=${currentCalendarDates[1]}`;
      }

      const response = await ApiService.get(url, {}, baseError);
      setEvents(response.data);
    } catch (error) {
      // Error handling is managed in ApiService
    } finally {
      setLoading(false);
    }
  };

  const loadDoctors = async () => {
    const baseError = 'Error obteniendo la lista de doctores.';
    const officeId = Auth.getCurrentOffice().officeId;

    try {
      const response = await ApiService.get(`${officeId}/doctors`, {}, baseError);
      const sortedDoctors = response.data.map(doctor => ({
        value: doctor.doctorId,
        label: doctor.name.toUpperCase(),
      })).sort((a, b) => a.label.localeCompare(b.label));

      setDoctors(sortedDoctors);

      if (Auth.getCurrentOffice().role === "Admin") {
        setDoctorsAdminView(sortedDoctors);
        setSelectedDoctorAdminView(sortedDoctors.length > 0 ? sortedDoctors[0] : null);
      }
    } catch (error) {
      // Error handling is managed in ApiService
    }
  };

  const loadPatients = async () => {
    const baseError = 'Error obteniendo la lista de pacientes.';
    const officeId = Auth.getCurrentOffice().officeId;

    try {
      const response = await ApiService.get(`${officeId}/patients`, {}, baseError);
      const sortedPatients = response.data.map(patient => ({
        value: patient.patientId,
        label: patient.name.toUpperCase(),
      })).sort((a, b) => a.label.localeCompare(b.label));
      setPatients(sortedPatients);
    } catch (error) {
      // Error handling is managed in ApiService
    }
  };

  const saveAppointment = async (baseError) => {
    const officeId = Auth.getCurrentOffice().officeId;
    const doctorId = viewType === "Patient" ? selectedDoctor.value : selectedDoctorAdminView?.value || Auth.getUserId();
    const doctorName = viewType === "Patient" ? selectedDoctor.label : selectedDoctorAdminView?.label || Auth.getUsername();
    const patientId = viewType === "Patient" ? Auth.getUserId() : selectedPatient.value;
    const patientName = viewType === "Patient" ? Auth.getUsername() : selectedPatient.label;
    const dto = {
      patientId,
      doctorId,
      officeId,
      start: currentEvent.start,
      end: currentEvent.end,
      title: currentEvent.title,
      description: currentEvent.extendedProps.description,
      status: currentEvent.extendedProps.status, 
      patientName: patientName,
      doctorName: doctorName,
      notes: currentEvent.extendedProps.notes,
      diagnosis: currentEvent.extendedProps.diagnosis,
      prescriptions: currentEvent.extendedProps.prescriptions,
      allergies: currentEvent.extendedProps.allergies,
      weight: currentEvent.extendedProps.weight,
      height: currentEvent.extendedProps.height,
      bloodPressure: currentEvent.extendedProps.bloodPressure,
      temperature: currentEvent.extendedProps.temperature
    };

    try {
      const url = viewType === "Patient" ? `${officeId}/patients/${patientId}/appointments` : `${officeId}/doctors/${doctorId}/appointments`;
      const method = isEditDelete ? 'put' : 'post';
      await ApiService[method](`${url}${isEditDelete ? `/${currentEvent.extendedProps.appointmentId}` : ''}`, dto, baseError);
      loadAppointments();
    } catch (error) {
      // Error handling is managed in ApiService
    }
  };

  const deleteAppointment = async (baseError) => {
    const officeId = Auth.getCurrentOffice().officeId;
    const doctorOrPatientId = viewType === "Patient" ? Auth.getUserId() : selectedDoctor.value;
    const url = viewType === "Patient" ?
     `${officeId}/patients/${doctorOrPatientId}/appointments/${currentEvent.extendedProps.appointmentId}` :
      `${officeId}/doctors/${doctorOrPatientId}/appointments/${currentEvent.extendedProps.appointmentId}`;

    try {
      await ApiService.delete(url, baseError);
      loadAppointments();
    } catch (error) {
      // Error handling is managed in ApiService
    }
  };

  const dateChangeHandler = (dateInfo) => {
    setCurrentCalendarDates([DateHelper.getIsoDateYYYYMMDD(dateInfo.start), DateHelper.getIsoDateYYYYMMDD(dateInfo.end)]);
  };

  const handleEventClick = (clickInfo) => {    
    setIsEditDelete(true);
    setCurrentEvent(clickInfo.event);
    setSelectedPatient({ value: clickInfo.event.extendedProps.patientId, label: clickInfo.event.extendedProps.patientName });
    setSelectedDoctor({ value: clickInfo.event.extendedProps.doctorId, label: clickInfo.event.extendedProps.doctorName });
    setIsAppointmentFormOpen(true);
  };

  const handleDateSelect = (selectInfo) => {
    defaultEvent.start = selectInfo.startStr;
    defaultEvent.end = selectInfo.endStr;
    setCurrentEvent(defaultEvent);
    setIsEditDelete(false);
    setSelectedPatient({});
    setIsAppointmentFormOpen(true);
  };

  const handleSubmit = async (e) => {    
    setLoading(true);
    setError('');
    const baseError = 'Error agregando cita.';
    const action = e.nativeEvent.submitter.id;

    try {
      if (action === "save") {
        await saveAppointment(baseError);
        ErrorHandling.showSuccessToast('Cita guardada exitosamente.');
        setIsAppointmentFormOpen(false);
      } else if (action === "delete") {
        await deleteAppointment(baseError);
        ErrorHandling.showSuccessToast('Cita eliminada exitosamente.');
        setIsAppointmentFormOpen(false);
      }
    } catch (error) {
      const customError = ErrorHandling.obtainErrorMessage(error);
      setError(baseError + (customError ? customError + ".": ''));
    } finally {
      setLoading(false);
    }
  };

  const handleEventContent = (arg) => {
    const startTime = arg.event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const endTime = arg.event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const status = arg.event.extendedProps.status;

    let backgroundColor;
    switch (status) {
      case "Completed":
        backgroundColor = "DarkGreen";
        break;
      case "Pending":
        backgroundColor = "DarkGoldenRod";
        break;
      case "Canceled":
        backgroundColor = "DarkRed";
        break;
      default:
        backgroundColor = "blue";
    }

    return (
      <div className="custom-event-content" style={{ backgroundColor }}>
        {startTime}-{endTime}<br />
        {viewType === "Patient" ? `${arg.event.extendedProps.doctorName?.toUpperCase()}` : `${arg.event.extendedProps.patientName?.toUpperCase()}`}
      </div>
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/calendar">Citas</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card">
                  <div className="card-body">
                    {error && <div className="error-box"><p>{error}</p></div>}
                    {viewType === "Admin" && (
                      <div className="form-group">
                        <label>Doctor: <span className="text-danger">*</span></label>
                        <Select
                          options={doctorsAdminView}
                          value={selectedDoctorAdminView}
                          onChange={(e) => setSelectedDoctorAdminView(e)}
                        />
                      </div>
                    )}
                    <div id="calendar">
                      <FullCalendar
                        ref={calendarRef}
                        expandRows={true}
                        locale={esLocale}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "timeGridWeek",
                        }}
                        initialView="timeGridWeek"
                        editable={viewType !== "Patient"}
                        selectable={viewType !== "Patient"}
                        selectMirror={viewType !== "Patient"}
                        dayMaxEvents={viewType !== "Patient"}
                        events={events}
                        select={handleDateSelect}
                        eventClick={handleEventClick}
                        eventContent={handleEventContent}
                        slotMinTime="07:00:00" // Set minimum time to 7 AM
                        slotMaxTime="22:00:00" // Set maximum time to 10 PM
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AppointmentForm
          isOpen={isAppointmentFormOpen}
          onRequestClose={() => setIsAppointmentFormOpen(false)}
          currentEvent={currentEvent}
          setCurrentEvent={setCurrentEvent}
          viewType={viewType}
          doctors={doctors}
          patients={patients}
          isEditDelete={isEditDelete}
          handleSubmit={handleSubmit}
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
          setSelectedDoctor={setSelectedDoctor}
          
        />

        {loading && (
          <div className="loading style-2">
            <div className="loading-wheel"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default Calendar;
