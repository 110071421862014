/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { 
  blog, dashboard, doctor, doctorschedule, logout, menuicon04, 
  menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, 
  menuicon12, menuicon14, menuicon15, menuicon16, patients, 
  settingicon01, sidemenu 
} from './imagepath';
import Scrollbars from "react-custom-scrollbars-2";
import * as Auth from "../helpers/auth-helper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

const Sidebar = (props) => {  
  const [sidebar, setSidebar] = useState("");
  const [userInfoObtained, setUserInfoObtained] = useState(false);
  const [userRole, setUserRole] = useState(''); 
  const [openSubmenus, setOpenSubmenus] = useState({}); // To track open state of submenus
  
  const toggleSubmenu = (key) => {
    setOpenSubmenus(prevState => ({
      ...prevState,
      [key]: !prevState[key] // Toggle the current submenu state
    }));
  };

  useEffect(() => {    
    // Get role
    setUserRole(Auth.getCurrentOffice()?.role);
    setUserInfoObtained(true);
  }, []);

  const logoutFunction = () => {      
    Auth.logout();
  };

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };

  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  if (!userInfoObtained) return <div></div>;

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                <li className="menu-title">Main</li>
                
                {userRole === "Admin" &&                  
                  <li className="submenu">
                    <Link to="#" id="menu-item1" onClick={() => {
                       setSidebar('Doctors');
                       toggleSubmenu('Doctors');
                    }}>
                      <span className="menu-side" style={{ marginRight: '10px' }}>
                        <img src={doctor} alt="" />
                      </span>
                      <span> Doctores </span>
                      <FontAwesomeIcon 
                        icon={faAngleRight} 
                        style={{ 
                          transform: openSubmenus['Doctors'] ? 'rotate(90deg)' : 'rotate(0deg)', 
                          transition: 'transform 0.15s', 
                          marginLeft: 'auto' 
                        }}
                      />
                    </Link>
                    <ul style={{ display: openSubmenus['Doctors'] ? 'block' : 'none' }} className="menu-items1">
                      <li>
                        <Link className={props?.activeClassName === 'doctor-list' ? 'active' : ''} to="/doctorlist">Lista de doctores</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'doctorform' ? 'active' : ''} to="/doctorform">Agregar doctor</Link>
                      </li>
                    </ul>
                  </li>
                }

                {(userRole === "Admin" || userRole === "Doctor") &&      
                  <li className="submenu">
                    <Link to="#" id="menu-item2" onClick={() => toggleSubmenu('Patients')}>
                      <span className="menu-side" style={{ marginRight: '10px' }}>
                        <img src={patients} alt="" />
                      </span>
                      <span>Pacientes </span>
                      <FontAwesomeIcon 
                        icon={faAngleRight} 
                        style={{ 
                          transform: openSubmenus['Patients'] ? 'rotate(90deg)' : 'rotate(0deg)', 
                          transition: 'transform 0.15s', 
                          marginLeft: 'auto' 
                        }}
                      />
                    </Link>
                    <ul style={{ display: openSubmenus['Patients'] ? 'block' : 'none' }} className="menu-items2">
                      <li>
                        <Link className={props?.activeClassName === 'patient-list' ? 'active' : ''} to="/patientlist">Lista de pacientes</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-patient' ? 'active' : ''} to="/patientform">Agregar pacientes</Link>
                      </li>                      
                    </ul>
                  </li>
                }

                <li className="submenu">
                  <Link to="#" id="menu-item4" onClick={() => toggleSubmenu('Appointments')}>
                    <span className="menu-side" style={{ marginRight: '10px' }}>
                      <img src={menuicon04} alt="" />
                    </span>
                    <span> Citas e historial </span>
                    <FontAwesomeIcon 
                      icon={faAngleRight} 
                      style={{ 
                        transform: openSubmenus['Appointments'] ? 'rotate(90deg)' : 'rotate(0deg)', 
                        transition: 'transform 0.15s', 
                        marginLeft: 'auto' 
                      }}
                    />
                  </Link>
                  <ul style={{ display: openSubmenus['Appointments'] ? 'block' : 'none' }} className="menu-items4">
                    {(userRole === "Admin" || userRole === "Doctor") &&      
                      <li>
                        <Link className={props?.activeClassName === 'calendar' ? 'active' : ''} to="/calendar">Vista de calendario</Link>
                      </li>        
                    }
                    <li>
                      <Link className={props?.activeClassName === 'medical-history' ? 'active' : ''} to="/clinicalhistory">Historial clinico</Link>
                    </li>                    
                  </ul>
                </li>

                <div className="logout-btn">              
                  <Link to="/passwordchange">
                    <span className="menu-side" style={{ marginRight: '10px' }}>
                      <img src={settingicon01} alt="" />
                    </span>
                    <span>Cambiar password</span>
                  </Link>              
                  <Link onClick={logoutFunction} to="/login">
                    <span className="menu-side" style={{ marginRight: '10px' }}>
                      <img src={logout} alt="" />
                    </span>
                    <span>Cerrar sesión</span>
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
