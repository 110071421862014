import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login02, loginlogo } from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as ErrorHandling from "../../../helpers/error-handling";
import ApiService from "../../../helpers/ApiService";
import * as Auth from "../../../helpers/auth-helper";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setPasswordHidden(!passwordHidden);
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true);
    const baseError = 'Error iniciando sesión.';
    try {
      const response = await ApiService.post('users/login', {
        email,
        password,
      }, baseError);      
      Auth.saveToken(response.data);
      navigate("/offices");        
      
    } catch (error) {
      // Error handling is managed in ApiService, showing toast messages automatically
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="#" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Inicia sesión</h2>
                      {/* Form */}
                      <form onSubmit={handleLogin}>
                        <div className="form-group">
                          <label>
                            Correo electrónico <span className="login-danger">*</span>
                          </label>
                          <input
                            required
                            onInvalid={(e) => ErrorHandling.requiredField(e)}
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <label>
                            Contraseña <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordHidden ? 'password' : 'text'}
                            className="form-control pass-input"
                            value={password}
                            required
                            onInvalid={(e) => ErrorHandling.requiredField(e)}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordHidden ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                          </span>
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              Recuérdame
                              <input type="checkbox" name="radio" />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">Olvidaste tu contraseña?</Link>
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            disabled={loading}
                          >
                            Iniciar sesión
                          </button>
                        </div>
                      </form>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Tienes un consultorio o una clínica? <Link to="/signup">Regístrate</Link>
                        </p>
                      </div>
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Eres un doctor o paciente? Pide al administrador de tu clínica o consultorio que te dé de alta.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
        {loading &&
          <div className="loading style-2">
            <div className="loading-wheel"></div>
          </div>
        }
      </div>
    </>
  );
};

export default Login;
