import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Auth from '../helpers/auth-helper';

const withAuthAndOfficeCheck = (WrappedComponent) => {
  const ComponentWithAuthCheck = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Check if the user is logged in
      if (!Auth.userLoggedIn()) {
        navigate('/login');
        return;
      }

      // Check if the current office is set
      if (!Auth.getCurrentOffice()) {
        navigate('/offices');
        return;
      }
    }, [navigate]);

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAuthCheck;
};

export default withAuthAndOfficeCheck;
