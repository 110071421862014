import React, { useState, useEffect } from 'react';
import { login02, loginlogo } from '../../imagepath';
import { Link } from 'react-router-dom';
import * as ErrorHandling from "../../../helpers/error-handling";
import ApiService from "../../../helpers/ApiService";

const AccountVerified = () => {
  const [paramsObtained, setParamsObtained] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const emailFromUrl = params.get('email');
    const activationCodeFromUrl = params.get('activationCode');
    setParamsObtained(true);

    const activateAccount = async () => {
      try {
        setError('');
          await ApiService.post('users/verification', {
          email: emailFromUrl,
          verificationToken: activationCodeFromUrl,
        }, 'Error en la activación.', { headers: { 'Accept-Language': 'es' } });
        ErrorHandling.showSuccessToast('Cuenta activada exitosamente.');        
      } catch (error) {
        // Errors are automatically handled by ApiService
      }
    };

    if (!emailFromUrl || !activationCodeFromUrl) {
      setError('Error en la activación. Confirme su correo de activación.');
      ErrorHandling.showErrorToast('Error en la activación. Confirme su correo de activación.');
    } else {
      activateAccount();
    }
  }, []);

  if (!paramsObtained) return <div></div>;

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="#">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      {error ? (
                        <div className="error-box">
                          <p>{error}</p>
                        </div>
                      ) : (
                        <div>
                          <h2>Listo, cuenta verificada</h2>
                          <div className="next-sign">
                            <p className="account-subtitle">
                              <Link to="/login">Inicie sesión</Link> aquí.
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountVerified;
